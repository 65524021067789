<script setup>
import { TransitionRoot } from '@headlessui/vue';
import { UseWindowSize } from '@vueuse/components';
import { ref, watch } from 'vue';
import { Link } from '@inertiajs/vue3';
import route from '@/Helpers/route.js';
import { useWindowScroll } from '@vueuse/core';

defineProps({
    restaurant: Object,
});

const { x, y } = useWindowScroll();

const show = ref(true);
const lastY = ref(0);

watch(y, (value) => {
    return;

    console.log([value, lastY.value, value > lastY.value]);

    show.value = value > lastY.value;
    lastY.value = value;
});
</script>

<template>


    <UseWindowSize v-slot="{ width }">
        <div class="isolate xl:hidden fixed left-0 right-0 bottom-0 px-4 bg-linear-to-t from-gray-500 transition-[padding-top] duration-250 z-50" :class="{ 'pt-[10vh]': show }" v-if="width < 768">
            <TransitionRoot as="div" class="fixed z-0 left-0 right-0 bottom-0" :show="show"
                            enter="transform transition duration-250" enter-from="translate-y-48 opacity-0" enter-to="translate-y-0 opacity-100"
                            leave="transform transition duration-250" leave-from="translate-y-0 opacity-100" leave-to="translate-y-48 opacity-0">
                <div class="flex flex-row">
                    <Link :href="route('website.menu', { restaurant })" class="
                        flex w-full items-center justify-center
                        cursor-pointer font-medium
                        #rounded-xl transition ease-in-out duration-150
                        bg-[var(--primary)] text-white p-4
                    " v-if="restaurant.can_deliver || restaurant.can_pickup">
                        Bestellen
                    </Link>

                    <Link :href="route('website.reservations.create', { restaurant })" class="
                        flex w-full items-center justify-center
                        cursor-pointer font-medium
                        #rounded-xl transition ease-in-out duration-150
                        bg-[var(--accent)] text-white p-4
                    " v-if="restaurant.accepts_reservations">
                        Reserveren
                    </Link>
                </div>
            </TransitionRoot>
        </div>
        <div class="w-full h-12" v-if="width < 768"></div>
    </UseWindowSize>

</template>
