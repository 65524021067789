<script setup>
import Footer from '@/Components/Website/Footer.vue';
import IsOnlineBanner from '@/Components/Shared/IsOnlineBanner.vue';
import Navbar from '@/Components/Website/Navbar.vue';
import BottomNavigation from '@/Components/Website/BottomNavigation.vue';
import { usePage } from '@inertiajs/vue3';

defineProps({
    restaurant: Object,
});

const page = usePage();
</script>

<template>
    <div class="min-h-screen bg-gray-100 font-website">
        <Navbar :restaurant="restaurant" v-if="restaurant.has_website" />

        <main class="min-h-[60vh]">
            <slot />
        </main>

        <Footer :restaurant="restaurant" />

        <IsOnlineBanner />

        <BottomNavigation
            :restaurant="restaurant"
            v-if="restaurant.has_website && !page.url.includes('bestellen') && !page.url.includes('checkout') && !page.url.includes('bestelling') && !page.url.includes('bestellen') && !page.url.includes('reserveren') && !page.url.includes('contact')"
        />
    </div>
</template>
